var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "search-toolbar-wrapper" },
        [_c("search-toolbar", { on: { search: _vm.search } })],
        1
      ),
      _c(
        "ejs-grid",
        {
          ref: "services-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            toolbar: _vm.toolbarOptions,
            searchSettings: _vm.searchOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings,
            toolbarClick: _vm.toolbarClick,
            commandClick: _vm.commandClick,
            beforeDataBound: _vm.onBeforeDataBound,
            childGrid: _vm.childGrid,
            detailDataBound: _vm.onDetailDataBound
          },
          on: {
            actionComplete: _vm.actionComplete,
            actionFailure: _vm.actionFailure
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Name",
                  headerText: _vm.$t("shared.name"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Description",
                  headerText: _vm.$t("shared.description"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Price",
                  headerText: _vm.$t("shared.price"),
                  width: "100",
                  type: "number",
                  format: "C2",
                  visible: !_vm.hasMultipleOffices
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "MultipleParticipants",
                  headerText: _vm.$t("services.multipleParticipants"),
                  width: "120",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "RoomRequired",
                  headerText: _vm.$t("services.roomRequired"),
                  width: "120",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "RoomMandatory",
                  headerText: _vm.$t("services.roomMandatory"),
                  width: "120",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EquipmentRequired",
                  headerText: _vm.$t("services.equipmentRequired"),
                  width: "120",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EquipmentMandatory",
                  headerText: _vm.$t("services.equipmentMandatory"),
                  width: "120",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: { width: "120", commands: _vm.commands }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }